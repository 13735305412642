import React from 'react';
import { Typography, TypographyFontWeightProps, TypographyVariantProps } from '../../atoms/Typography';
import { COLORS } from '../../../configs/colors';
import { PartSearchItemProps } from './PartSearchItem.props';
import { MicroText, StyledStack } from './PartSearchItem.styles';

export const PartSearchItem: React.FC<PartSearchItemProps> = ({
  data,
  searchType
}) => {
  if (searchType === 'dispatch') {
    return (
      <StyledStack direction="column">
        <Typography textColor={COLORS.Blue} variant={TypographyVariantProps.Subtitle2}>{data.displayId}</Typography>
        <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>
          S/N: <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption} fontWeight={TypographyFontWeightProps.Bold}>{data.serial1}</Typography>
        </Typography>
        <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>Part Type: {data.name}</Typography>
      </StyledStack>
    );
  } else return (
    <StyledStack direction="column">
      <Typography textColor={COLORS.Blue} variant={TypographyVariantProps.Subtitle2}>{data.serial1}</Typography>
      <MicroText>{data.locationName}</MicroText>
      <MicroText>{`AM-PID-${data.id}`}</MicroText>
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.partCondition?.name}</Typography>
      <Typography textColor={COLORS.Black} variant={TypographyVariantProps.Caption}>{data.partType?.name}</Typography>
    </StyledStack>
  );
};