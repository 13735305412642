import { GridFilterItem } from '@mui/x-data-grid-pro';
import { LabelValue } from '../@types';
import { DuplicatePartsDataType, PartConditionDropdown, SfhOptions } from '../@types/part.type';

export const getSFHDropDownSelectedLabel = (dropdownValue: string, sfhOptions: Array<SfhOptions>) => {
  return sfhOptions.find((sfhOption: SfhOptions) => sfhOption.value === dropdownValue)?.label;
};

export const getPartConditionSelectedLabel = (dropdownValue: string, conditions: Array<PartConditionDropdown>) => {
  return conditions.find((condition: PartConditionDropdown) => condition.value === dropdownValue)?.label || '';
};

export const getFleetTagDropDownSelectedLabel = (dropdownValue: string | number, fleetTagOptions?: Array<LabelValue>) => {
  return fleetTagOptions?.find((fleetTagOption: LabelValue) => fleetTagOption.value === dropdownValue)?.label;
};

export const getPartConditionLabelsfromValues = (selectedConditions: string [] | string, conditionsList?: PartConditionDropdown[]) => {
  if (typeof selectedConditions === 'string') {
    return conditionsList?.find((condition) => condition.value === selectedConditions)?.label as string;
  } else {
    const partConditions: string[] = [];
    selectedConditions.forEach((selectedCondition) => {
      partConditions.push(conditionsList?.find((condition) => condition.value === selectedCondition)?.label as string);
    });
    return partConditions.join();
  }
};

export const disablePartsMergeButton = (mergePartList: DuplicatePartsDataType[]) => {
  return mergePartList.every((part) => !part.allowMerge);
};

export const getPartListInitialFilterModel = (location: string | null) => {
  if (location) {
    return [
      {
        columnField: 'nodeName',
        operatorValue: 'equals',
        value: location,
      },
    ];
  } else return [];
};

export const getPartListFilterState = (
  location: string | null,
  initialFilterModel: GridFilterItem[]
) => {
  if (location) {
    return initialFilterModel;
  } else return [];
};