import { ListItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Typography } from '../../atoms/Typography';

export const LocationGrid = styled(Grid)`
  padding-top: 10px;
`;

export const ContactTypography = styled(Typography)`
  margin-top: 10px;
`;

export const StyledListItem = styled(ListItem)`
  padding: 0px;
`;