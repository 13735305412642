import React, { useEffect, useState, KeyboardEvent, useRef } from 'react';
import { Grid, Button } from '@mui/material';
import { TLCLoader, TLCHeading, TLCLoaderText, TLCBox, TLCSubHeading, TLCText, TLCSerialScan, TLCManifestContainer, TLCNote } from './TLC.styles';
import { ColorProps, SizeProps } from '../../../@types';
import { TLCTemplateDetailsDataType } from '../../../@types/tlc.type';
import { Loader, LoaderColorProps } from '../../../components/atoms/Loader';
import { Switch } from '../../../components/atoms/Switch';
import { FormControlLabel } from '../../../components/atoms/FormControlLabel';
import { PageContainer } from '../../../components/atoms/PageContainer';
import { TypographyVariantProps } from '../../../components/atoms/Typography';
import { TLCTemplateList } from '../../../components/organisms/TLCTemplateList';
import { TLCManifestList } from '../../../components/templates/TLCManifestList';
import { TLCManifestRowItemValueType } from '../../../components/organisms/TLCManifestRowItem';
import { TLCTemplateBehavior, TLCTemplateNotePrompt } from '../../../configs/enums';
import { useGetTlcTemplateDetails, useExecuteTlcTransactions } from '../../../queries/tlc-query';
import { useGetPartConditions } from '../../../queries/part-query';
import { useGetPrintLabel } from '../../../queries/print-label-query';
import { PrintLabelEntityTypes } from '../../../@types/print-label.type';
import { printLabel } from '../../../utils/print-label-utils';
import { getPartConditionLabelsfromValues } from '../../../utils/part';
import { isDuplicatedSerial } from '../../../utils/tlc';
import { PLATFORM_NAME } from '../../../configs/common';
import { TabHandler } from '../../../handlers/TabHandler';

export const TLC: React.FC = () => {
  const [templatesData, setTemplatesData] = useState<TLCTemplateDetailsDataType[]>([]);
  const [selectedTemplateData, setSelectedTemplateData] = useState<TLCTemplateDetailsDataType>();
  const [manifest, setManifest] = useState<TLCManifestRowItemValueType[]>([]);
  const [printLabelOption, setPrintLabelOption] = useState<boolean>(false);
  const [serialValue, setSerialValue] = useState<string>('');
  const [serialLoader, setSerialLoader] = useState<boolean>(false);
  const [batchLoader, setBatchLoader] = useState<boolean>(false);
  const [printLabelPartId, setPrintLabelPartId] = useState<number>(0);
  const [batchExecutionComplete, setBatchExecutionComplete] = useState<boolean>(false);
  const [locationChangeFlag, setLocationChangeFlag] = useState<boolean>(false);

  const getTlcTemplateDetails = useGetTlcTemplateDetails();
  const getPartConditions = useGetPartConditions();
  const executeTlcTransactions = useExecuteTlcTransactions();
  const getPrintLabel = useGetPrintLabel(printLabelPartId, PrintLabelEntityTypes.Part);

  const inputRef: any = useRef(null);

  TabHandler(`T.L.C | ${PLATFORM_NAME}`);

  useEffect(() => {
    printLabelPartId !== 0 && onClickPrintLabel();
  }, [printLabelPartId]);

  useEffect(() => {
    getTlcTemplateDetails.data && setTemplatesData(getTlcTemplateDetails.data.sort((template1,template2) => template2.id-template1.id));
  }, [getTlcTemplateDetails.data]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [locationChangeFlag]);

  const onKeyDown = async(event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setSerialLoader(true);
      event.preventDefault();
      const { validationResult, partCurrentData }: any = await executeTlcTransactions.mutateAsync({
        selectedTemplateData: selectedTemplateData,
        serialValue: serialValue
      });
      if (isDuplicatedSerial(manifest, validationResult)) {
        validationResult.validation = false;
        validationResult.errors.push(' Duplicated serial ');
      }
      const scannedPart = {
        ...validationResult,
        id: manifest.length > 0 ? Math.max(...manifest.map(item => item.id)) + 1 : 1,
        errors: typeof(validationResult?.errors) === 'string' ? validationResult?.errors : validationResult?.errors.join(),
        partCurrentData
      };
      const updatedManifest = [ ...manifest, scannedPart ];
      setManifest(updatedManifest);
      setSerialValue('');
      setSerialLoader(false);
      if (printLabelOption && validationResult.execution &&
        selectedTemplateData?.behavior.toLowerCase() === TLCTemplateBehavior.Immediate) {
        setPrintLabelPartId(validationResult.partId);
      }
    }
  };

  const batchSubmit = async() => {
    setBatchLoader(true);
    const batchExecutionResult: any = await executeTlcTransactions.mutateAsync({
      selectedTemplateData: selectedTemplateData,
      batchData: manifest
    });
    const mappedResult = batchExecutionResult.map((partResult: any) => {
      return {
        ...partResult,
        errors: typeof(partResult?.errors) === 'string' ? partResult?.errors : partResult?.errors.join()
      };
    });
    setManifest(mappedResult);
    setBatchLoader(false);
    setBatchExecutionComplete(true);
  };

  const onClickPrintLabel = async () => {
    const labelToPrint = await getPrintLabel.mutateAsync({});
    printLabel(labelToPrint);
  };

  const createNewBatch = () => {
    setManifest([]);
    const updatedData = {
      ...selectedTemplateData,
      data: {
        ...selectedTemplateData?.data,
        templateAction:{
          ...selectedTemplateData?.data.templateAction,
          batchNote: ''
        }
      }
    };
    setSelectedTemplateData(updatedData as TLCTemplateDetailsDataType);
    setBatchExecutionComplete(false);
  };

  return (
    <PageContainer>
      <TLCHeading variant={TypographyVariantProps.H5}>
        T.L.C
      </TLCHeading>
      {getTlcTemplateDetails.isLoading && 
        <TLCLoader>
          <Loader color={LoaderColorProps.Primary}/>
        </TLCLoader>
      }
      {!templatesData.length ?
        <TLCLoaderText variant={TypographyVariantProps.H5}>
          No active TLC templates found
        </TLCLoaderText>
        :
        <Grid container>
          <Grid>
            <TLCTemplateList
              templateData={templatesData}
              expanded={true}
              onClickItem={(template) => {
                setManifest([]);
                setSerialValue('');
                setSelectedTemplateData(template);
                setPrintLabelOption(false);
                setSerialLoader(false);
                setBatchLoader(false);
                setPrintLabelPartId(0);
              }}
            />
          </Grid>
          {selectedTemplateData ? 
            <Grid xs={true}>
              <TLCBox>
                <Grid container>
                  <Grid item xs={12}>
                    <TLCHeading variant={TypographyVariantProps.H6}>
                      Name: <TLCText variant={TypographyVariantProps.Caption}>{selectedTemplateData.name}</TLCText>
                    </TLCHeading>
                    <TLCHeading variant={TypographyVariantProps.H6}>
                      Description: <TLCText variant={TypographyVariantProps.Caption}>{selectedTemplateData.description}</TLCText>
                    </TLCHeading>
                    <TLCHeading variant={TypographyVariantProps.H6}>
                      Behavior: <TLCText variant={TypographyVariantProps.Caption}>{selectedTemplateData.behavior}</TLCText>
                    </TLCHeading>
                    <TLCHeading variant={TypographyVariantProps.H6} sx={{ marginBottom: '-10px' }}>
                      Restrictions:
                    </TLCHeading>
                    <TLCSubHeading variant={TypographyVariantProps.Caption}> Part Types: </TLCSubHeading><TLCText variant={TypographyVariantProps.Caption}>{selectedTemplateData.data.restrictions.partTypeNames.join()}</TLCText> |
                    <TLCSubHeading variant={TypographyVariantProps.Caption}> Location: </TLCSubHeading><TLCText variant={TypographyVariantProps.Caption}>{selectedTemplateData.data.restrictions.locationNames.join()}</TLCText> |
                    <TLCSubHeading variant={TypographyVariantProps.Caption}> Include Child: </TLCSubHeading><TLCText variant={TypographyVariantProps.Caption}>{selectedTemplateData.data.restrictions.location.includeChild ? 'Yes' : 'No'}</TLCText> |
                    <TLCSubHeading variant={TypographyVariantProps.Caption}> Conditions: </TLCSubHeading><TLCText variant={TypographyVariantProps.Caption}>{getPartConditionLabelsfromValues(selectedTemplateData.data.restrictions.conditions,getPartConditions.data)}</TLCText> |
                    <TLCSubHeading variant={TypographyVariantProps.Caption}> Hardware Revisions: </TLCSubHeading><TLCText variant={TypographyVariantProps.Caption}>{selectedTemplateData.data.restrictions?.hwRevisionNames ? selectedTemplateData.data.restrictions.hwRevisionNames.join(): 'Any'}</TLCText> |
                    <TLCSubHeading variant={TypographyVariantProps.Caption}> Software Versions: </TLCSubHeading><TLCText variant={TypographyVariantProps.Caption}>{selectedTemplateData.data.restrictions?.swVersionNames ? selectedTemplateData.data.restrictions.swVersionNames.join(): 'Any'}</TLCText> |
                    <TLCSubHeading variant={TypographyVariantProps.Caption}> Firmware Versions: </TLCSubHeading><TLCText variant={TypographyVariantProps.Caption}>{selectedTemplateData.data.restrictions?.fwVersionNames ? selectedTemplateData.data.restrictions.fwVersionNames.join(): 'Any'}</TLCText> |
                    <TLCSubHeading variant={TypographyVariantProps.Caption}> Fleet Tags: </TLCSubHeading><TLCText variant={TypographyVariantProps.Caption}>{selectedTemplateData.data.restrictions?.fleetTagNames ? selectedTemplateData.data.restrictions.fleetTagNames.join(): 'Any'}</TLCText>
                    <TLCHeading variant={TypographyVariantProps.H6} sx={{ marginTop: '15px', marginBottom: '-10px' }}>
                      Template Actions:
                    </TLCHeading>
                    <TLCSubHeading variant={TypographyVariantProps.Caption}> Action Type: </TLCSubHeading><TLCText variant={TypographyVariantProps.Caption} >{selectedTemplateData.data.templateAction.templateActionTypeName} </TLCText>|
                    {selectedTemplateData.data.templateAction.partTypeName && 
                      <>
                        <TLCSubHeading variant={TypographyVariantProps.Caption}> Part Type: </TLCSubHeading>
                        <TLCText variant={TypographyVariantProps.Caption}>{selectedTemplateData.data.templateAction.partTypeName} </TLCText>|
                      </>
                    }
                    {selectedTemplateData.data.templateAction.condition && 
                      <>
                        <TLCSubHeading variant={TypographyVariantProps.Caption}> Condition: </TLCSubHeading>
                        <TLCText variant={TypographyVariantProps.Caption} >{getPartConditionLabelsfromValues(selectedTemplateData.data.templateAction.condition,getPartConditions.data)} </TLCText>|
                      </>
                    }
                    {selectedTemplateData.data.templateAction.locationName && 
                      <>
                        <TLCSubHeading variant={TypographyVariantProps.Caption}> Location: </TLCSubHeading>
                        <TLCText variant={TypographyVariantProps.Caption} >{selectedTemplateData.data.templateAction.locationName} </TLCText>|
                      </>
                    }
                    <TLCSubHeading variant={TypographyVariantProps.Caption}> Location Prompt: </TLCSubHeading>
                    <TLCText variant={TypographyVariantProps.Caption} >{selectedTemplateData.data.templateAction.locationPrompt ? 'Yes' : 'No'} </TLCText>|
                    {selectedTemplateData.data.templateAction.hardwareRevisionName && 
                      <>
                        <TLCSubHeading variant={TypographyVariantProps.Caption}> Hardware Revision: </TLCSubHeading>
                        <TLCText variant={TypographyVariantProps.Caption} >{selectedTemplateData.data.templateAction.hardwareRevisionName} </TLCText>|
                      </>
                    }
                    {selectedTemplateData.data.templateAction.softwareVersionName && 
                      <>
                        <TLCSubHeading variant={TypographyVariantProps.Caption}> Software Version: </TLCSubHeading>
                        <TLCText variant={TypographyVariantProps.Caption} >{selectedTemplateData.data.templateAction.softwareVersionName} </TLCText>|
                      </>
                    }
                    {selectedTemplateData.data.templateAction.firmwareVersionName && 
                      <>
                        <TLCSubHeading variant={TypographyVariantProps.Caption}> Firmware Version: </TLCSubHeading>
                        <TLCText variant={TypographyVariantProps.Caption} >{selectedTemplateData.data.templateAction.firmwareVersionName} </TLCText>|
                      </>
                    }
                    {!!selectedTemplateData.data.templateAction.partActions.length &&
                      <>
                        <TLCSubHeading variant={TypographyVariantProps.Caption}> Part Actions: </TLCSubHeading>{
                          selectedTemplateData.data.templateAction.partActions.map((partAction,index) => <TLCText key={index} variant={TypographyVariantProps.Caption}> {partAction.selectedRowAction} - {partAction.rowNote} |</TLCText>)
                        }
                      </> 
                    }
                    {selectedTemplateData.data.templateAction.note && 
                      <>
                        <TLCSubHeading variant={TypographyVariantProps.Caption}> Note: </TLCSubHeading>
                        <TLCText variant={TypographyVariantProps.Caption} >{selectedTemplateData.data.templateAction.note} </TLCText>|
                      </>
                    }
                    {selectedTemplateData.data.templateAction.notePrompt && 
                      <>
                        <TLCSubHeading variant={TypographyVariantProps.Caption}> Note Prompt: </TLCSubHeading>
                        <TLCText variant={TypographyVariantProps.Caption} >{selectedTemplateData.data.templateAction.notePrompt.prompt ? 'True ' : 'False '}{ selectedTemplateData.data.templateAction.notePrompt.behavior}</TLCText>
                      </>
                    }
                    {selectedTemplateData.data.templateAction.tagNames && 
                      <>
                        |<TLCSubHeading variant={TypographyVariantProps.Caption}> Tags: </TLCSubHeading>
                        <TLCText variant={TypographyVariantProps.Caption}>{selectedTemplateData.data.templateAction.tagNames.join()}</TLCText>
                      </>
                    }
                  </Grid>
                  <Grid container xs={12} mt={7} mb={7} direction="row" alignItems="center" justifyContent="center">
                    {!batchExecutionComplete &&
                      <TLCSerialScan
                        inputRef={inputRef}
                        size={SizeProps.Medium}
                        label="Scan Serial 1"
                        value={serialValue}
                        onKeyDown={onKeyDown}
                        onChange={(event) => setSerialValue(event.target.value)}
                        InputProps={{
                          endAdornment: serialLoader && <Loader color={LoaderColorProps.Success}/>
                        }}
                      />
                    }
                    {selectedTemplateData.behavior.toLowerCase() === TLCTemplateBehavior.Immediate &&
                      <FormControlLabel
                        control={<Switch color={ColorProps.Success} onChange={()=>setPrintLabelOption(!printLabelOption)} checked={printLabelOption}/>}
                        label="Print Label"
                      />
                    }
                  </Grid>
                  {!!manifest.length &&
                    <TLCManifestContainer>
                      <TLCManifestList
                        manifestData={manifest.sort((part1, part2) => part2.id - part1.id)}
                        conditionsList={getPartConditions.data || []}
                        templateData={selectedTemplateData}
                        batchExecutionComplete={batchExecutionComplete}
                        onChangeLocation={async(locationData, id) => {
                          const manifestItems = [ ...manifest ];
                          const partData = manifestItems.find((part) => part.id === id) as TLCManifestRowItemValueType;
                          partData.newLocation.id = locationData.id;
                          partData.newLocation.name = locationData.displayName;
                          partData.newLocation.type = locationData.partLocationType;
                          setManifest(manifestItems);
                          setLocationChangeFlag(!locationChangeFlag);
                        }}
                        onChangeNote={(note,id) => {
                          const manifestItems = [ ...manifest ];
                          const partData = manifestItems.find((part) => part.id === id) as TLCManifestRowItemValueType;
                          partData.note = note;
                          setManifest(manifestItems);
                        }}
                        onDelete={(id) => {
                          const manifestItems = [ ...manifest ];
                          const deleteItemIndex = manifestItems.findIndex((manifestItem) => manifestItem.id === id);
                          manifestItems.splice(deleteItemIndex, 1);
                          setManifest(manifestItems);
                        }}
                      />
                    </TLCManifestContainer>
                  } 
                  {!batchExecutionComplete ?
                    <Grid container xs={12} mt={7} mb={5} direction="row" alignItems="center" justifyContent="center">
                      {selectedTemplateData.behavior.toLowerCase() === TLCTemplateBehavior.Batch &&
                      selectedTemplateData.data.templateAction.notePrompt.prompt &&
                      selectedTemplateData.data.templateAction.notePrompt.behavior.toLowerCase() === TLCTemplateNotePrompt.Batch &&
                        <Grid>
                          <TLCNote
                            InputProps={{
                              readOnly: !manifest.some((part) => part.validation)
                            }}
                            placeholder="Provide a note for the batch"
                            multiline
                            size={SizeProps.Medium}
                            value={selectedTemplateData.data.templateAction.batchNote}
                            onChange={(event) => {
                              const updatedData = {
                                ...selectedTemplateData,
                                data: {
                                  ...selectedTemplateData.data,
                                  templateAction:{
                                    ...selectedTemplateData.data.templateAction,
                                    batchNote: event.target.value
                                  }
                                }
                              };
                              setSelectedTemplateData(updatedData);
                            }}
                          />
                        </Grid>
                      }
                      {selectedTemplateData.behavior.toLowerCase() === TLCTemplateBehavior.Batch && 
                        <Grid>
                          <Button disabled={!manifest.some((part) => part.validation)} variant="contained" onClick={batchSubmit}>{batchLoader ? <Loader color={LoaderColorProps.Success}/> : 'Submit'}</Button>
                        </Grid>
                      }
                    </Grid>
                    :
                    <Grid container mt={7} mb={5} justifyContent="center">
                      <Button variant="contained" color={ColorProps.Success} onClick={createNewBatch}>Create New Batch Manifest</Button>
                    </Grid>
                  }
                </Grid>
              </TLCBox>
            </Grid>
            :
            <Grid xs={true} mt={6} ml={5}>
              <TLCHeading variant={TypographyVariantProps.H5}>
               Select a Template to Get Started
              </TLCHeading>
            </Grid>
          }
        </Grid>
      }
    </PageContainer>
  );
};