import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useMediaQuery } from '@material-ui/core';
import { PartHistoryProps } from './PartHistory.props';
import { DateCellWrapper, GridContainer, SmallPartLabel, StyledWrapper, XmlButtonWrapper } from './PartHistory.styles';
import { Accordian } from '../../atoms/Accordian';
import { formatDate } from '../../../utils/common';
import { Typography, TypographyVariantProps } from '../../atoms/Typography';
import { Link } from '../../atoms/Link';
import { Button, ButtonVariantProps } from '../../atoms/Button';
import { ScreenSize } from '../../../@types/display.types';
import { MOBILE_DATA_GRID_COLUMN } from '../../../configs/ui-constants';
import { MobileDataGridItem } from '../../organisms/MobileDataGridItem';
import { CustomDataGrid } from '../../layouts/CustomDataGrid';
import { IntegrationInstructionsOutlined } from '@mui/icons-material';
import { getUploadedFilePresignedUrlByKey } from '../../../api/uploadedFiles';

export const PartHistory: React.FC<PartHistoryProps> = ({
  partId,
  partHistoryData,
  isLoading
}) => {
  const isMobileView = useMediaQuery(ScreenSize.MOBILE);
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Date/Timestamp',
      renderCell: (params) => (
        <DateCellWrapper>
          <Typography variant={TypographyVariantProps.Body2}>{formatDate(params.row.createdAt)}</Typography>
          {params.row.attachmentKey &&
            <XmlButtonWrapper>
              <Button variant={ButtonVariantProps.Icon} aria-label="download" onClick={() => downloadPartHistoryFile(params.row.attachmentKey)}>
                <IntegrationInstructionsOutlined />
              </Button>
            </XmlButtonWrapper>
          }
        </DateCellWrapper>
      )
    },
    {
      field: 'createdBy',
      headerName: 'User',
    },
    {
      field: 'historyTypeCode',
      headerName: 'Code',
    },
    {
      field: 'condition',
      headerName: 'Condition',
    },
    {
      field: 'toNode',
      headerName: 'To',
      valueGetter: (params) => params.row.toNode,
      renderCell: (params: GridRenderCellParams) => getPartHistoryLocationLinks(params.row.toNode, params.row.toLocationIds, params.row.toLocationNodeTypes)
    },
    {
      field: 'fromNode',
      headerName: 'From',
      valueGetter: (params) => params.row.fromNode,
      renderCell: (params: GridRenderCellParams) => getPartHistoryLocationLinks(params.row.fromNode, params.row.fromLocationIds, params.row.fromLocationNodeTypes)
    },
    {
      field: 'notes',
      headerName: 'Notes'
    },
    {
      field: 'serviceId',
      headerName: 'Service',
      renderCell: (params: GridRenderCellParams) =>
        params.row.serviceId && <Link href={`/services/${params.row.serviceId}`}>{params.row.serviceId}</Link>
    },
    {
      field: 'shipmentId',
      headerName: 'Shipment',
      renderCell: (params: GridRenderCellParams) =>
        params.row.shipmentId && <Link href={`/shipments/${params.row.shipmentId}`}>{params.row.shipmentId}</Link>
    },
    {
      field: MOBILE_DATA_GRID_COLUMN,
      headerName: '',
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        return (
          <MobileDataGridItem data={[
            {
              title: 'Date/Timestamp',
              value: <Typography variant={TypographyVariantProps.Body2}>{formatDate(row.createdAt)}</Typography>,
            },
            {
              title: 'User',
              value: <SmallPartLabel>{row.createdBy}</SmallPartLabel>,
            },
            {
              title: 'Code',
              value: <SmallPartLabel>{row.historyTypeCode}</SmallPartLabel>,
            },
            {
              title: 'Condition',
              value: <SmallPartLabel>{row.condition}</SmallPartLabel>,
            },
            {
              title: 'To',
              value: <SmallPartLabel>{row.toNode}</SmallPartLabel>,
            },
            {
              title: 'From',
              value: <SmallPartLabel>{row.fromNode}</SmallPartLabel>,
            },
            {
              title: 'Service',
              value: row.serviceId && <Link href={`/services/${row.serviceId}`}>{row.serviceId}</Link>,
            },
            {
              title: 'Shipment',
              value: row.shipmentId && <Link href={`/shipments/${row.shipmentId}`}>{row.shipmentId}</Link>,
            },
          ]}
          />
        );
      }
    }
  ];

  const maxOfCol = (colIndex: number) => {
    const invisibleContainer = document.createElement('div');
    invisibleContainer.setAttribute('style', 'visibility: hidden; z-index: -9999999999; position: absolute; font-size: 14px; top: 0; left: 0;');
    document.body.append(invisibleContainer);

    const widths: any[] = [];

    document.querySelectorAll(`[aria-colindex="${colIndex + 1}"]`).forEach(cell => {

      const invisibleCell = document.createElement('div');
      invisibleCell.innerHTML = cell.innerHTML;
      invisibleCell.setAttribute('style', 'width: 100%; max-width: 100%; min-width: 100%;');
      invisibleContainer.append(invisibleCell);
      widths.push(Math.ceil(invisibleCell.clientWidth + 1));
    });
    
    let max = Math.max(...widths);

    if (max !== 0 && max < 30) { max = 30; }

    invisibleContainer.remove();

    return max;
  };

  const resizeColumns = () => {
    const cols = [...columns]; // columns is my columns state.

    cols.forEach((col, index) => {
      const maxColWidth = maxOfCol(index) + 20;
      col.width = maxColWidth;
    });

    return cols;
  };

  // ToDo: This is a temporary fix. WIll remove this after Mui-Datagrid-Pro library update
  useEffect(() => {
    if (partHistoryData.length > 0) {
      setTimeout(() => {
        setIsPageLoaded(true);
      }, 1000);
    }
  }, [partHistoryData]);
  
  const downloadPartHistoryFile= async(key: string) => {
    const fileData = await getUploadedFilePresignedUrlByKey(key);
    if(fileData){
      window.open(fileData.url, '_blank');
    }
  };

  const getPartHistoryLocationLinks = (locationData: string, locationIdData: string, locationNodeTypesData: string) => {
    const locations = locationData?.split(' / ');
    const locationIds = locationIdData?.split(' / ');
    const locationNodeTypes = locationNodeTypesData?.split(' / ');
    const links = 
      <Grid container>
        {locations?.map((location, index) => (
          <Grid item key={index}><Link href={locationNodeTypes[index] === 'sibling' ? `configure/users/${locationIds[index]}` : `structure/${locationIds[index]}`}>{location}</Link>{index !== locations.length - 1 && ' /'}&nbsp;</Grid>
        ))}
      </Grid>;
      
    return links;
  };

  return (
    <StyledWrapper>
      <Accordian title={
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography>Part History</Typography>
          </Grid>
          <Grid item mr={2}>
            <Link href={`/parts/movements?partId=${partId}`} target="_blank">
              <Button variant={ButtonVariantProps.Secondary} onClick={(e) => e.stopPropagation()}>
                All Records
              </Button>
            </Link>
          </Grid>
        </Grid>
      } defaultExpanded={true}>
        <GridContainer>
          <CustomDataGrid
            columns={isPageLoaded ? resizeColumns() : columns}
            isMobile={isMobileView}
            rows={partHistoryData}
            getRowId={(row) => row?.id}
            disableColumnFilter={true}
            disableColumnSelector={true}
            disableDensitySelector={true}
            pagination={false}
            enableCSVExport={false}
            exportFileName={''}
            componentsProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true },
                showQuickFilter: false
              },
            }}
            getRowHeight={() => 'auto'}
            loading={isLoading}
          />
        </GridContainer>
      </Accordian>
    </StyledWrapper>
  );
};